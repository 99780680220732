import React from 'react';
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <header className="bg-white shadow-sm">
      <nav className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div className="w-full py-6 flex items-center justify-between border-b border-[#dde6fc] lg:border-none">
          <div className="flex items-center">
            <Link to="/">
              <span className="sr-only">Simone's Clinic Management</span>
              <img
                className="h-20"
                src="/images/logo.png"
                alt="Simone Clinic Management"
              />
            </Link>
            <div className="hidden ml-10 space-x-8 lg:block">
              <a href="#features" className="text-base font-medium text-gray-700 hover:text-[#3257c3]">
                Features
              </a>
              <a href="#pricing" className="text-base font-medium text-gray-700 hover:text-[#3257c3]">
                Pricing
              </a>
              {/* Uncomment this when you add a testimonials section */}
              {/* <a href="#testimonials" className="text-base font-medium text-gray-700 hover:text-[#3257c3]">
                Testimonials
              </a> */}
            </div>
          </div>
          <div className="ml-10 space-x-4">
            <Link
              to="/signin"
              className="hidden sm:inline-block bg-white border-2 border-[#3257c3] py-2 px-4 rounded-md text-base font-medium text-[#3257c3] hover:bg-[#dde6fc]"
            >
              Sign in
            </Link>
            <Link
              to="/register"
              className="inline-block bg-[#3257c3] py-2 px-4 border border-transparent rounded-md text-base font-medium text-white hover:bg-[#69b4fc]"
            >
              Start Now
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;