import React, { useState } from "react";

export default function StepOne({ onSubmit, initialData }) {
    const [formData, setFormData] = useState({
        fullName: initialData.fullName || "",
        email: initialData.email || "",
        clinicName: initialData.clinicName || "",
        clinicType: initialData.clinicType || "",
    });
    const [formError, setFormError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError(null);

        if (!validateEmail(formData.email)) {
            setFormError("Invalid email address.");
            return;
        }
        if (!formData.fullName || !formData.clinicName || !formData.clinicType) {
            setFormError("Please fill in all fields.");
            return;
        }

        onSubmit(formData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    function validateEmail(email) {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label htmlFor="fullName" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Full Name
                </label>
                <div className="mt-2">
                    <input
                        id="fullName"
                        name="fullName"
                        type="text"
                        value={formData.fullName}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="email" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Email address
                </label>
                <div className="mt-2">
                    <input
                        id="email"
                        name="email"
                        type="email"
                        value={formData.email}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="clinicName" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Clinic Name
                </label>
                <div className="mt-2">
                    <input
                        id="clinicName"
                        name="clinicName"
                        type="text"
                        value={formData.clinicName}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="clinicType" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Clinic Type
                </label>
                <div className="mt-2">
                    <select
                        id="clinicType"
                        name="clinicType"
                        value={formData.clinicType}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    >
                        <option value="">Select clinic type</option>
                        <option value="Medical">Medical</option>
                        <option value="Dental">Dental</option>
                        <option value="Veterinary">Veterinary</option>
                        <option value="Therapy">Therapy</option>
                        <option value="Other">Other</option>
                    </select>
                </div>
            </div>

            {formError && <p className="text-red-500">{formError}</p>}

            <div>
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#3257c3] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#69b4fc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3257c3]"
                >
                    Next Step
                </button>
            </div>
        </form>
    );
} 