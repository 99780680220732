import React, { useState } from "react";

export default function StepFour({ onSubmit, onBack, initialData }) {
    const [formData, setFormData] = useState({
        staffing: {
            doctors: initialData.staffing?.doctors || 0,
            nurses: initialData.staffing?.nurses || 0,
            receptionists: initialData.staffing?.receptionists || 0,
            administrators: initialData.staffing?.administrators || 0,
            technicians: initialData.staffing?.technicians || 0,
            other: initialData.staffing?.other || 0,
        },
    });
    const [formError, setFormError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError(null);

        // Validate that at least one staff member is entered
        const totalStaff = Object.values(formData.staffing).reduce((a, b) => a + b, 0);
        if (totalStaff === 0) {
            setFormError("Please enter at least one staff member.");
            return;
        }

        onSubmit(formData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        const numValue = parseInt(value) || 0;

        if (numValue < 0) return; // Prevent negative numbers

        setFormData((prev) => ({
            staffing: {
                ...prev.staffing,
                [name]: numValue,
            },
        }));
    };

    const staffTypes = [
        { id: "doctors", label: "Doctors/Physicians" },
        { id: "nurses", label: "Nurses" },
        { id: "receptionists", label: "Receptionists" },
        { id: "administrators", label: "Administrators" },
        { id: "technicians", label: "Technicians" },
        { id: "other", label: "Other Staff" },
    ];

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
                <h3 className="text-lg font-medium text-[#3257c3]">Staff Information</h3>
                <p className="text-sm text-gray-500">
                    Please indicate how many staff members you have in each role:
                </p>

                {staffTypes.map((staffType) => (
                    <div key={staffType.id}>
                        <label
                            htmlFor={staffType.id}
                            className="block text-sm font-medium leading-6 text-[#3257c3]"
                        >
                            {staffType.label}
                        </label>
                        <div className="mt-2">
                            <input
                                type="number"
                                name={staffType.id}
                                id={staffType.id}
                                min="0"
                                value={formData.staffing[staffType.id]}
                                onChange={handleChange}
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                            />
                        </div>
                    </div>
                ))}
            </div>

            {formError && <p className="text-red-500">{formError}</p>}

            <div className="flex justify-between space-x-4">
                <button
                    type="button"
                    onClick={onBack}
                    className="flex w-full justify-center rounded-md bg-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#3257c3] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#69b4fc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3257c3]"
                >
                    Complete Registration
                </button>
            </div>
        </form>
    );
} 