import React from 'react';

const HeroSection = () => {
  return (
    <div className="relative bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16 sm:py-24 lg:py-32">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="lg:w-1/2 lg:pr-8">
            <div className="flex items-center mb-4">
              <div className="bg-[#3257c3] rounded-full p-1 mr-2">
                <svg className="w-5 h-5 text-white" fill="currentColor" viewBox="0 0 20 20">
                  <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                </svg>
              </div>
              <p className="text-[#3257c3] font-semibold text-sm">Streamline your clinic operations</p>
            </div>
            <h1 className="text-4xl sm:text-5xl lg:text-6xl font-bold text-gray-900 leading-tight mb-4">
              Manage your clinic <br />
              with ease <br />
              and efficiency
            </h1>
            <p className="text-lg text-gray-600 mb-8">
              Simplify your clinic management with our all-in-one software solution.
              From scheduling and payroll to client management,
              <span className="font-bold text-[#3257c3]"> Simone Clinic Management </span>
              helps you focus on patient care while we handle the rest.
            </p>
            <a
              href="#"
              className="inline-block w-full sm:w-auto px-6 py-3 bg-[#3257c3] text-white font-bold rounded-lg text-center hover:bg-[#69b4fc] transition duration-300"
            >
              Start Now
            </a>
            <div className="mt-6 flex items-center">
              <div className="flex -space-x-2 overflow-hidden">
                {[1, 2, 3].map((index) => (
                  <img
                    key={index}
                    className="inline-block h-8 w-8 rounded-full ring-2 ring-white"
                    src={`https://randomuser.me/api/portraits/women/${index}.jpg`}
                    alt=""
                  />
                ))}
              </div>
              <span className="ml-3 text-sm font-semibold text-gray-700">Join our growing community of users</span>
            </div>
          </div>
          <div className="lg:w-1/2 mt-10 lg:mt-0">
            <div className="relative">
              <img
                src="/images/appointment_summary2.png"
                alt="Simone Clinic Management dashboard"
                className="rounded-lg shadow-xl w-full h-auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
