import React from 'react';

const features = [
  {
    name: 'Patient Management',
    description: 'Easily manage patient records, appointments, and medical histories with our secure platform.',
    icon: (
      <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 48 48">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M32 24h4a4 4 0 110 8h-4m-8 8v-8m8-8V16a8 8 0 10-16 0v8m0 0H16a4 4 0 00-4 4v8a4 4 0 004 4h16a4 4 0 004-4v-8a4 4 0 00-4-4h-4z" />
      </svg>
    ),
  },
  {
    name: 'Billing and Invoicing',
    description: 'Simplify and automate billing, invoicing, and payment tracking for your clinic.',
    icon: (
      <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 48 48">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M24 16c-3.314 0-6 2.686-6 6v2H14a4 4 0 00-4 4v2h20v-2a4 4 0 00-4-4h-4v-2c0-1.104.896-2 2-2s2 .896 2 2v2h4v-2c0-3.314-2.686-6-6-6z" />
      </svg>
    ),
  },
  {
    name: 'QuickBooks Integration',
    description: 'Seamlessly integrate with QuickBooks to streamline your billing and accounting processes.',
    icon: (
      <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 48 48">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M24 16c-3.314 0-6 1.79-6 4s2.686 4 6 4 6 1.79 6 4-2.686 4-6 4m0-16v16m0 0v6m0-6H18m6 0h6M18 16h2.492a2 2 0 011.922 1.45l3.172 11.1a2 2 0 001.922 1.45H30" />
      </svg>
    ),
  },
  {
    name: 'Reporting and Analytics',
    description: 'Gain insights into your clinic\'s performance with detailed reports and analytics.',
    icon: (
      <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 48 48">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 34v-8a4 4 0 014-4h4a4 4 0 014 4v8m-12 8h12a4 4 0 004-4V26a4 4 0 00-4-4h-3.172a2 2 0 01-1.414-.586l-4-4A2 2 0 0021.172 18H18a4 4 0 00-4 4v12a4 4 0 004 4z" />
      </svg>
    ),
  },
  {
    name: 'Automatic Task Assignment',
    description: 'Automatically assign tasks to the appropriate clinic staff for each step, such as clinicians, front desk, and SOAP notes completion.',
    icon: (
      <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 48 48">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M18 34v-8a4 4 0 014-4h4a4 4 0 014 4v8m-12 8h12a4 4 0 004-4V26a4 4 0 00-4-4h-3.172a2 2 0 01-1.414-.586l-4-4A2 2 0 0021.172 18H18a4 4 0 00-4 4v12a4 4 0 004 4z" />
      </svg>
    ),
  },
  {
    name: 'Payroll Calculation',
    description: 'Automatically calculate fee for service invoices and streamline your payroll process.',
    icon: (
      <svg className="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 48 48">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v28a4 4 0 004 4h16a4 4 0 004-4V8m-24 0h24M12 14h24M12 20h24M12 26h24M12 32h24" />
      </svg>
    ),
  },
];

const FeatureSection = () => {
  return (
    <section className="py-16 bg-gray-50" id="features">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-base text-[#3257c3] font-semibold tracking-wide uppercase">Clinic Management Features</h2>
          <p className="mt-2 text-3xl font-extrabold text-gray-900 sm:text-4xl">
            Empower Your Clinic with Seamless Management Tools
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 mx-auto">
            From patient management to billing and analytics, our software is designed to streamline every aspect of your clinic.
          </p>
        </div>

        <div className="mt-12 grid grid-cols-1 md:grid-cols-2 gap-8">
          {features.map((feature) => (
            <div key={feature.name} className="flex">
              <div className="flex-shrink-0">
                <div className="flex items-center justify-center h-12 w-12 rounded-md bg-[#3257c3] text-white">
                  {feature.icon}
                </div>
              </div>
              <div className="ml-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{feature.name}</h3>
                <p className="mt-2 text-base text-gray-500">{feature.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;
