import React, { useState } from "react";

export default function StepThree({ onSubmit, onBack, initialData }) {
    const [formData, setFormData] = useState({
        previousSoftware: initialData.previousSoftware || "no",
        previousSoftwareName: initialData.previousSoftwareName || "",
        numberOfClients: initialData.numberOfClients || "",
        monthlyAppointments: initialData.monthlyAppointments || "",
    });
    const [formError, setFormError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError(null);

        if (formData.previousSoftware === "yes") {
            if (!formData.previousSoftwareName) {
                setFormError("Please specify the name of your previous software.");
                return;
            }
        }

        // Validate numbers if provided
        if (formData.numberOfClients && !/^\d+$/.test(formData.numberOfClients)) {
            setFormError("Number of clients must be a valid number.");
            return;
        }
        if (formData.monthlyAppointments && !/^\d+$/.test(formData.monthlyAppointments)) {
            setFormError("Monthly appointments must be a valid number.");
            return;
        }

        onSubmit(formData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
            // Reset related fields when changing previous software status
            ...(name === "previousSoftware" && value === "no" && {
                previousSoftwareName: "",
            }),
        }));
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Are you transitioning from another software package?
                </label>
                <div className="mt-2">
                    <div className="flex space-x-4">
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="previousSoftware"
                                value="yes"
                                checked={formData.previousSoftware === "yes"}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-[#3257c3]"
                            />
                            <span className="ml-2">Yes</span>
                        </label>
                        <label className="inline-flex items-center">
                            <input
                                type="radio"
                                name="previousSoftware"
                                value="no"
                                checked={formData.previousSoftware === "no"}
                                onChange={handleChange}
                                className="form-radio h-4 w-4 text-[#3257c3]"
                            />
                            <span className="ml-2">No</span>
                        </label>
                    </div>
                </div>
            </div>

            {formData.previousSoftware === "yes" && (
                <div>
                    <label htmlFor="previousSoftwareName" className="block text-sm font-medium leading-6 text-[#3257c3]">
                        Which software package are you currently using?
                    </label>
                    <div className="mt-2">
                        <input
                            id="previousSoftwareName"
                            name="previousSoftwareName"
                            type="text"
                            value={formData.previousSoftwareName}
                            onChange={handleChange}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>
            )}

            <div>
                <label htmlFor="numberOfClients" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Approximately how many clients do you have? (Optional)
                </label>
                <div className="mt-2">
                    <input
                        id="numberOfClients"
                        name="numberOfClients"
                        type="number"
                        value={formData.numberOfClients}
                        onChange={handleChange}
                        placeholder="Enter number of clients"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="monthlyAppointments" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Average monthly appointments? (Optional)
                </label>
                <div className="mt-2">
                    <input
                        id="monthlyAppointments"
                        name="monthlyAppointments"
                        type="number"
                        value={formData.monthlyAppointments}
                        onChange={handleChange}
                        placeholder="Enter average monthly appointments"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            {formError && <p className="text-red-500">{formError}</p>}

            <div className="flex justify-between space-x-4">
                <button
                    type="button"
                    onClick={onBack}
                    className="flex w-full justify-center rounded-md bg-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#3257c3] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#69b4fc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3257c3]"
                >
                    Next Step
                </button>
            </div>
        </form>
    );
} 