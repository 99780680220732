import React, { useState } from "react";
import { US_STATES, CANADIAN_PROVINCES, COUNTRIES } from "../../constants/locations";

export default function StepTwo({ onSubmit, onBack, initialData }) {
    const [formData, setFormData] = useState({
        streetAddress: initialData.streetAddress || "",
        city: initialData.city || "",
        state: initialData.state || "",
        country: initialData.country || "US",
        postalCode: initialData.postalCode || "",
        ownerName: initialData.ownerName || "",
        ownerPhone: initialData.ownerPhone || "",
    });
    const [formError, setFormError] = useState(null);

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormError(null);

        if (Object.values(formData).some(value => !value)) {
            setFormError("Please fill in all fields.");
            return;
        }

        // Validate postal code format based on country
        const usZipRegex = /^\d{5}(-\d{4})?$/;
        const caPostalRegex = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
        
        if (formData.country === "US" && !usZipRegex.test(formData.postalCode)) {
            setFormError("Please enter a valid US ZIP code (e.g., 12345 or 12345-6789).");
            return;
        } else if (formData.country === "CA" && !caPostalRegex.test(formData.postalCode)) {
            setFormError("Please enter a valid Canadian postal code (e.g., A1A 1A1).");
            return;
        }

        // Validate phone number format
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!phoneRegex.test(formData.ownerPhone)) {
            setFormError("Please enter a valid phone number (e.g., 123-456-7890).");
            return;
        }

        onSubmit(formData);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
            // Reset state/province when country changes
            ...(name === 'country' && { state: '' }),
        }));
    };

    const getStateOptions = () => {
        if (formData.country === "US") {
            return US_STATES.map(state => (
                <option key={state.value} value={state.value}>{state.label}</option>
            ));
        } else if (formData.country === "CA") {
            return CANADIAN_PROVINCES.map(province => (
                <option key={province.value} value={province.value}>{province.label}</option>
            ));
        }
        return <option value="">Select state/province</option>;
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6">
            <div>
                <label htmlFor="streetAddress" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Street Address
                </label>
                <div className="mt-2">
                    <input
                        id="streetAddress"
                        name="streetAddress"
                        type="text"
                        value={formData.streetAddress}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="country" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Country
                </label>
                <div className="mt-2">
                    <select
                        id="country"
                        name="country"
                        value={formData.country}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    >
                        {COUNTRIES.map(country => (
                            <option key={country.value} value={country.value}>{country.label}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
                <div>
                    <label htmlFor="city" className="block text-sm font-medium leading-6 text-[#3257c3]">
                        City
                    </label>
                    <div className="mt-2">
                        <input
                            id="city"
                            name="city"
                            type="text"
                            value={formData.city}
                            onChange={handleChange}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                        />
                    </div>
                </div>

                <div>
                    <label htmlFor="state" className="block text-sm font-medium leading-6 text-[#3257c3]">
                        {formData.country === "US" ? "State" : "Province"}
                    </label>
                    <div className="mt-2">
                        <select
                            id="state"
                            name="state"
                            value={formData.state}
                            onChange={handleChange}
                            required
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                        >
                            {getStateOptions()}
                        </select>
                    </div>
                </div>
            </div>

            <div>
                <label htmlFor="postalCode" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    {formData.country === "US" ? "ZIP Code" : "Postal Code"}
                </label>
                <div className="mt-2">
                    <input
                        id="postalCode"
                        name="postalCode"
                        type="text"
                        value={formData.postalCode}
                        onChange={handleChange}
                        required
                        placeholder={formData.country === "US" ? "12345" : "A1A 1A1"}
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="ownerName" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Owner Name
                </label>
                <div className="mt-2">
                    <input
                        id="ownerName"
                        name="ownerName"
                        type="text"
                        value={formData.ownerName}
                        onChange={handleChange}
                        required
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            <div>
                <label htmlFor="ownerPhone" className="block text-sm font-medium leading-6 text-[#3257c3]">
                    Owner Phone
                </label>
                <div className="mt-2">
                    <input
                        id="ownerPhone"
                        name="ownerPhone"
                        type="tel"
                        value={formData.ownerPhone}
                        onChange={handleChange}
                        required
                        placeholder="123-456-7890"
                        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-[#69b4fc] placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#3257c3] sm:text-sm sm:leading-6"
                    />
                </div>
            </div>

            {formError && <p className="text-red-500">{formError}</p>}

            <div className="flex justify-between space-x-4">
                <button
                    type="button"
                    onClick={onBack}
                    className="flex w-full justify-center rounded-md bg-gray-100 px-3 py-1.5 text-sm font-semibold leading-6 text-gray-900 shadow-sm hover:bg-gray-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-600"
                >
                    Back
                </button>
                <button
                    type="submit"
                    className="flex w-full justify-center rounded-md bg-[#3257c3] px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-[#69b4fc] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#3257c3]"
                >
                    Next Step
                </button>
            </div>
        </form>
    );
}