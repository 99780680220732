import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    currentStep: 1,
    totalSteps: 4,
    formData: {
        // Step 1
        fullName: "",
        email: "",
        clinicName: "",
        clinicType: "",
        
        // Step 2
        clinicAddress: "",
        streetAddress: "",
        city: "",
        state: "",
        zipCode: "",
        ownerName: "",
        ownerPhone: "",
        
        // Step 3
        previousSoftware: "",
        previousSoftwareName: "",
        numberOfClients: "",
        monthlyAppointments: "",
        
        // Step 4
        staffing: {
            doctors: 0,
            nurses: 0,
            receptionists: 0,
            administrators: 0,
            technicians: 0,
            other: 0
        }
    },
    isComplete: false,
    lastSaved: null
};

const registrationProgressSlice = createSlice({
    name: "registrationProgress",
    initialState,
    reducers: {
        updateFormData: (state, action) => {
            state.formData = {
                ...state.formData,
                ...action.payload
            };
            state.lastSaved = new Date().toISOString();
        },
        nextStep: (state) => {
            if (state.currentStep < state.totalSteps) {
                state.currentStep += 1;
            }
        },
        previousStep: (state) => {
            if (state.currentStep > 1) {
                state.currentStep -= 1;
            }
        },
        setStep: (state, action) => {
            if (action.payload >= 1 && action.payload <= state.totalSteps) {
                state.currentStep = action.payload;
            }
        },
        completeRegistration: (state) => {
            state.isComplete = true;
        },
        resetProgress: () => initialState
    }
});

export const {
    updateFormData,
    nextStep,
    previousStep,
    setStep,
    completeRegistration,
    resetProgress
} = registrationProgressSlice.actions;

export default registrationProgressSlice.reducer; 