"use client";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { registerUserAsync } from "../store/thunks/authThunk";
import { updateFormData, nextStep, previousStep } from "../store/slices/registrationProgressSlice";
import StepOne from "./registration/StepOne";
import StepTwo from "./registration/StepTwo";
import StepThree from "./registration/StepThree";
import StepFour from "./registration/StepFour";

export default function Register() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentStep, formData, isComplete } = useSelector(
        (state) => state.registrationProgress
    );
    const { loading, error } = useSelector((state) => state.auth);

    useEffect(() => {
        if (isComplete) {
            navigate("/login");
        }
    }, [isComplete, navigate]);

    const handleStepSubmit = async (stepData) => {
        dispatch(updateFormData(stepData));
        
        if (currentStep === 4) {
            try {
                await dispatch(registerUserAsync(formData));
            } catch (err) {
                console.error("Registration error:", err);
            }
        } else {
            dispatch(nextStep());
        }
    };

    const handleBack = () => {
        dispatch(previousStep());
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return <StepOne onSubmit={handleStepSubmit} initialData={formData} />;
            case 2:
                return <StepTwo onSubmit={handleStepSubmit} onBack={handleBack} initialData={formData} />;
            case 3:
                return <StepThree onSubmit={handleStepSubmit} onBack={handleBack} initialData={formData} />;
            case 4:
                return <StepFour onSubmit={handleStepSubmit} onBack={handleBack} initialData={formData} />;
            default:
                return null;
        }
    };

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8 bg-[#f8fafc]">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                <img
                    className="mx-auto h-50 w-auto"
                    src="/images/logo.png"
                    alt="Your Company"
                />
                <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-[#3257c3]">
                    Sign Up for Our Clinic Management Software
                </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="mb-8">
                    <div className="flex justify-between items-center">
                        {[1, 2, 3, 4].map((step) => (
                            <div
                                key={step}
                                className={`flex items-center ${
                                    step <= currentStep ? "text-[#3257c3]" : "text-gray-300"
                                }`}
                            >
                                <div
                                    className={`w-8 h-8 rounded-full flex items-center justify-center border-2 ${
                                        step <= currentStep
                                            ? "border-[#3257c3] bg-white"
                                            : "border-gray-300"
                                    }`}
                                >
                                    {step}
                                </div>
                                {step < 4 && (
                                    <div
                                        className={`h-1 w-12 ${
                                            step < currentStep ? "bg-[#3257c3]" : "bg-gray-300"
                                        }`}
                                    />
                                )}
                            </div>
                        ))}
                    </div>
                </div>

                {error && <p className="text-red-500 text-center mb-4">{error}</p>}
                
                <div className="bg-white p-8 rounded-lg shadow">
                    {renderStep()}
                </div>
            </div>
        </div>
    );
}
