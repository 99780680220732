import React from 'react';
import { Link } from 'react-router-dom';
import Checkout from '../../components/Checkout';

const CTASection = () => {
  return (
    <div className="bg-indigo-700 mt-32">
      <div className="max-w-2xl mx-auto text-center py-16 px-4 sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-white sm:text-4xl">
          <span className="block">Boost your clinic's efficiency.</span>
          <span className="block">Start using our app today.</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-indigo-200">
          Join thousands of healthcare professionals who have transformed their practice with our clinic management software.
        </p>
        <div className="mt-8 flex justify-center space-x-4">
          <Link
            to="/register"
            className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-indigo-600 bg-white hover:bg-indigo-50"
          >
            Start Now
          </Link> 
        </div>
      </div>
    </div>
  );
};

export default CTASection;