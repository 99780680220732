import React from 'react';

const BenefitItem = ({ icon, title }) => (
  <div className="flex items-center space-x-4">
    <div className="flex-shrink-0">
      {icon}
    </div>
    <p className="text-lg font-bold text-gray-700">{title}</p>
  </div>
);

const BenefitsSection = () => {
  return (
    <section className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-2 lg:gap-8 items-center">
          <div className="mb-10 lg:mb-0">
            <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl mb-4">
              Retain 10x more clients with<br />detailed metric tracking
            </h2>
            <p className="text-xl text-gray-600 mb-6">
              Transform your clinic's potential into performance. Witness the real results that our tool brings to the table, amplifying efficiency and patient care. Our software makes it easier for healthcare providers to manage their practice.
            </p>
            <a
              href="#"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Start Now
            </a>
            <div className="mt-8 space-y-4">
              <BenefitItem
                icon={<svg className="w-6 h-6 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>}
                title="Boost your clinic's efficiency and patient reach"
              />
              <BenefitItem
                icon={<svg className="w-6 h-6 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>}
                title="Streamline patient records and appointments"
              />
              <BenefitItem
                icon={<svg className="w-6 h-6 text-indigo-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" /></svg>}
                title="Scale your patient outreach and follow-ups"
              />
            </div>
          </div>
          <div className="relative">
            <img
              className="rounded-lg shadow-xl"
              src="/images/dashboard.png"
              alt="Clinic Management Dashboard"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BenefitsSection;