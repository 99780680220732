import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-gray-50" aria-labelledby="footer-heading">
      <h2 id="footer-heading" className="sr-only">Footer</h2>
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        <div className="xl:grid xl:grid-cols-3 xl:gap-8">
          <div className="space-y-8 xl:col-span-1">
            <img
              className="h-20"
              src="/images/logo.png"
              alt="Simone Clinic Management"
            />
            <p className="text-gray-500 text-base">
              Making clinic management easier and more efficient.
            </p>
            <div className="flex space-x-6">
              {/* Add social media links here if needed */}
            </div>
          </div>
          <div className="mt-12 grid grid-cols-2 gap-8 xl:mt-0 xl:col-span-2">
            <div className="md:grid md:grid-cols-2 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Solutions
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                      Appointment Scheduling
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                      Patient Management
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                      Billing
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="mt-12 md:mt-0">
                <h3 className="text-sm font-semibold text-gray-400 tracking-wider uppercase">
                  Support
                </h3>
                <ul className="mt-4 space-y-4">
                  <li>
                    <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                      Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                      Documentation
                    </Link>
                  </li>
                  <li>
                    <Link to="#" className="text-base text-gray-500 hover:text-gray-900">
                      Guides
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-12 border-t border-gray-200 pt-8">
          <p className="text-base text-gray-400 xl:text-center">
            &copy; 2024 Simone's Clinic Management, Inc. All rights reserved.
          </p>
          <div className="mt-2 xl:text-center space-x-4">
            <Link to="/privacy-policy" className="text-base text-gray-500 hover:text-gray-900">
              Privacy Policy
            </Link>
            <Link to="/terms-of-service" className="text-base text-gray-500 hover:text-gray-900">
              Terms of Service
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;